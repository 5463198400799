<template>
  <ReportIndex
    :fst-id="fstId"
    :pageTitle="title"
    :qso="qso"
    :endpoint="endpoint"
    :filterItems="filterOptions"
  />
</template>

<script>
import ReportIndex from '@/composites/report/index/Index.vue'
import { useEndpoints } from '@/composables'

export default {
  name: 'ViewOrganizationUserReports',

  components: {
    ReportIndex,
  },
  data() {
    return {
      fstId: null,
      title: 'Reports',
      qso: { append: '', prepend: '' },
      endpoint: null,
      filterOptions: [],
    }
  },
  async created() {
    this.fstId = `franchise-reports`
    this.generateEndPoint()
    await this.generateFilterOptions()
  },
  methods: {
    generateEndPoint() {
      this.endpoint =
        useEndpoints.report.index() +
        `?franchise_user=${this.$route.params.id}&`
    },
    async generateFilterOptions() {
      const filterableFleets = []
      const url = useEndpoints.fleet.indexOfStaff(this.$route.params.id)
      await this.$http.get(url).then(({ data }) => {
        if (data.data.length) {
          data.data.forEach((fleet) => {
            filterableFleets.push({ text: fleet.name, value: fleet.id })
          })
        }
      })
      this.filterOptions = [
        {
          key: 'fleet',
          type: 'checkbox',
          input: filterableFleets,
          title: 'Fleet',
        },
        {
          key: 'report_category',
          type: 'checkbox',
          input: [
            { text: 'Damaged Vehicle', value: 'DAMAGED_VEHICLE' },
            { text: 'Need Maintenance', value: 'NEED_MAINTENANCE' },
            { text: 'Wrongly Parked', value: 'WRONGLY_PARKED' },
            { text: 'Missing', value: 'MISSING' },
            { text: 'Out of service area', value: 'OUT_OF_SERVICE_AREA' },
            { text: 'Restricted area', value: 'RESTRICTED_AREA' },
            { text: 'Other', value: 'OTHER' },
          ],
          title: 'Category',
        },

        {
          key: 'report_status',
          type: 'checkbox',
          input: [
            { text: 'Attention Required', value: 'ATTENTION_REQUIRED' },
            { text: 'In Review', value: 'UNDER_PROGRESS' },
            { text: 'Resolved', value: 'RESOLVED' },
          ],
          title: 'Status',
        },
        {
          key: 'report_type',
          type: 'checkbox',
          input: [
            { text: 'Rental', value: 'rental' },
            { text: 'Trip', value: 'trip' },
          ],
          title: 'Type',
        },
      ]
    },
  },
}
</script>
